<template>
    <v-container class="login fill-height" fluid>
        <v-alert type="error" class="alert alert-error elevation-3" v-model="alert" :dismissible="true">{{ alertText }}</v-alert>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-20 login-card">
                    <v-toolbar color="#031E4D" dark flat>
                        <v-toolbar-title class="d-flex">
                            <img src="../../assets/images/DarallPro_sign_screen.svg" class="mr-3" width=24 />{{ $t('auth.title') }}
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field :label="$t('auth.login')" name="name" placeholder="" prepend-icon="mdi-account" type="text" v-model="name" v-on:keyup.enter="auth" hide-details></v-text-field>
                        <v-text-field
                            :append-icon="pass_show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="pass_show ? 'text' : 'password'"
                            :label="$t('auth.password')"
                            name="password"
                            placeholder=""
                            v-model="password"
                            prepend-icon="mdi-lock"
                            @click:append="pass_show = !pass_show"
                            v-on:keyup.enter="auth"
                            hide-details
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions class="mx-2">
                        <v-row align="center">
                            <v-col cols="12" md="6">
                                <v-checkbox class="ma-2" v-model="accept" dense hide-details>
                                    <template v-slot:label>
                                        <div>
                                            Я ознакомился и согласен с
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <a target="_blank" href="https://darall.pro/legal" @click.stop v-on="on"> правилами сервисов Darall </a>
                                                </template>
                                                Перейти к документам сервисов Darall
                                            </v-tooltip>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="6" class=" d-flex justify-end">
                                <v-btn class="ma-2" :loading="loading" color="#031E4D" style="width: 160px" text-color="white" @click="auth" dark> <v-icon v-if="loading">mdi-refresh</v-icon>Войти </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <div v-if="!cookieAccept" class="cookie-wrapper">
            <div class="cookie" style="display: block">
                <a class="close-cookie" href="#" @click="onClickCloseCookie"></a>
                <p>
                    <span class="cookie-icon">🍪</span>
                    Мы используем файлы cookie, чтобы пользоваться сайтом было удобно. Продолжив работу с сайтом, вы соглашаетесь с <a target="_blank" href="https://darall.pro/policy.pdf">политикой конфиденциальности</a>.
                </p>
            </div>
        </div>

        <div class="footer" align="center" justify="center">
            <div class="link">
                <a target="_blank" href="https://darall.pro/legal">📃 Правовые документы</a>
            </div>
            <div class="organization">© ИП Сухих П.В. Владивосток 2025</div>
        </div>
    </v-container>
</template>

<script>
    export default {
        props: {
            source: String,
        },
        data() {
            return {
                name: '',
                password: '',
                alert: false,
                alertText: '',
                loading: false,
                pass_show: false,
                cookieAccept: true,
                accept: false,
            }
        },
        mounted() {
            this.cookieAccept = this.$cookies.isKey('cookie-accept')
            this.accept = this.$cookies.isKey('accept')
            this.name = ' '
            setTimeout(() => {
                this.name = ''
            }, 100)
        },
        methods: {
            onClickCloseCookie() {
                this.cookieAccept = true
                this.$cookies.set('cookie-accept', true, '3y')
            },
            auth() {
                if (!this.accept) {
                    this.alert = true
                    this.loading = false
                    this.alertText = 'Поставьте галочку, что вы согласны с правилами сервисов Darall'
                } else {
                    let name = this.name.toLowerCase()
                    let password = this.password.toLowerCase()
                    this.alert = false
                    this.loading = true
                    this.$store
                        .dispatch('auth', { name, password })
                        .then(res => {
                            if (res.data.success) {
                                this.$cookies.set('accept', true, '3y')
                                this.$router.push({ name: 'app' })
                            } else {
                                this.alert = true
                                this.loading = false
                                this.alertText = 'Неверный логин или пароль'
                            }
                        })
                        .catch(err => {
                            this.alert = true
                            this.loading = false
                            this.alertText = 'Ошибка входа. Возможно, нет доступа к Интернету'
                            console.log(err)
                        })
                }
            },
        },
    }
</script>

<style lang="stylus">
    html
        overflow-y auto !important
    #app
        width 100%
        height 100%
        z-index 1
        display block
        position absolute
        min-width 320px

    .login-card
        z-index 1
        min-width 400px
        @media screen and (max-width 560px)
            min-width 300px
            // top 10px
            // position absolute

    .login
        min-height 480px
        background url("../../assets/images/background.jpg") 100% 100% no-repeat
        background-size cover

    .alert-error
        top 40px
        position absolute
        margin: 20px;
        min-width 300px
        z-index 2
        @media screen and (max-width 767px)
            top 0px
            margin: 0px;

    .footer
        position absolute
        left 0
        right 0
        bottom 0
        height 70px
        background-color rgba(0,0,0,.4)
        padding 10px 0
        display: flex;
        flex-direction column
        .link
            font-family Montserrat,Arial,sans-serif
            height 30px
            a
                color #a7b2bd !important
                line-height 1.2em
                font-size 16px
                font-weight 500
                position relative
                display inline-block
                text-decoration none

                &:hover
                    text-decoration none
                    color #fff !important

    .cookie-wrapper
        position absolute
        left 0
        width 100%
        bottom 0
        display flex
        justify-content center


    .cookie
        position relative
        max-width 580px
        padding 20px 65px 20px 20px
        border-radius 10px
        background #fff
        box-shadow 0 10px 30px 0 rgba(0,0,0,0.1)
        font-size 14px
        line-height 1.43
        transition transform 1s ease-in-out
        z-index 1

        @media screen and (max-width 1200px)
            margin 10px


        @media screen and (max-width 767px)
            bottom 25px
            max-width 100%


        .cookie-icon
            display inline-block
            font-size 65px
            float left
            line-height 65px
            margin-bottom 0
            text-align center
            margin-right 20px
            cursor default


            @media screen and (max-width 767px)
                display none

        &.hide
            transform translateY(2000px)

        .close-cookie
            position absolute
            right 17px
            top 24px
            overflow hidden
            text-indent -999px
            background url(../../assets/images/close.svg) no-repeat
            width 25px
            height 25px

        p
            margin 0

        p a
            color #3c51bf
            text-decoration none
            &:hover
                color #5c71df

    .organization
        color #dddddd !important
        font-size 14px
</style>
